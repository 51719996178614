<template>
  <div>
    <div>
      <el-form layout="inline">
        <el-row :gutter="24">
          <el-col
            :md="6"
            :sm="8"
            v-for="(value, code) in roleList"
            v-bind:key="code"
          >
            <el-button
              v-if="value.seniorStatus != '2'"
              :disabled="value.seniorStatus == '3'"
              placeholder
              style="width: 60%"
              v-model="queryParam.fundName"
              @click="getCouponInfoButton(value)"
              >{{ value.name }}</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div style="margin: 40px 0px">
      <h2>{{ this.title }}</h2>
      <span>{{ this.description }}</span>
      <br />
      <h3 style="color: red" v-if="this.title != null">
        活动时间:{{ timeStamp(this.start_time) }} 到
        {{ timeStamp(this.end_time) }}
      </h3>
    </div>

    <div style="margin: 100px">
      <el-link
        :disabled="this.h5_short_link == null"
        :href="this.h5_short_link"
        target="_blank"
        style="font-family: arial; font-size: 30px; text-align: center"
        >链接1--点击跳转</el-link
      >
      <br />
      <el-link
        :disabled="this.h5_url == null"
        :href="this.h5_url"
        target="_blank"
        style="font-family: arial; font-size: 30px; text-align: center"
        >链接2--点击跳转</el-link
      >
      <br />
      <el-link
        :disabled="this.ele_scheme_url == null"
        :href="this.ele_scheme_url"
        target="_blank"
        style="font-family: arial; font-size: 30px; text-align: center"
        >链接3--点击跳转</el-link
      >
      <br />
      <br />
      <div class="demo-image__preview">
        <el-image
          style="width: 200px; height: 200px"
          :src="url"
          :preview-src-list="srcList"
        >
        </el-image>
      </div>

      <br />
    </div>
  </div>
</template>
<script>
import { timeStamp } from "@/utils/xun";
import { getCouponListApi } from "@/api/system/mune/menuCoupon.js";
import { getDataApi } from "@/api/coupon/taobaoApi.js";
export default {
  props: ["homeMenus"],
  data() {
    return {
      queryParam: {},
      roleList: [],

      clickUrl1: null,
      clickUrl2: null,
      clickUrl3: null,
      clickUrl4: null,
      reslutData: {
        title: null,
        link: {
          h5_short_link: null,
        },
      },
      url: "",
      srcList: [],
      start_time: null,
      end_time: null,
      description: null,
      title: null,

      h5_url: null,
      h5_short_link: null,
      ele_scheme_url: null,
    };
  },
  mounted() {
    this.getMuneList();
  },
  //防止第一次点击失效
  created() {
    this.getMuneList();
  },
  methods: {
    getMuneList() {
      console.log(this.homeMenus.code);
      var query = {
        type: 3,
        resources: this.homeMenus.code,
        productSource: this.homeMenus.productSource,
      };
      getCouponListApi(query).then((res) => {
        this.roleList = res.result;
      });
    },
    clearUrl() {
      this.clickUrl1 = null;
      this.clickUrl2 = null;
      this.clickUrl3 = null;
      this.h5_url = null;
      this.h5_short_link = null;
      this.ele_scheme_url = null;
      this.description = null;
      this.start_time = null;
      this.end_time = null;
      this.title = null;
    },
    getCouponInfoButton(val) {
      this.clearUrl();
      var query = {
        menuCode: val.code,
        typeParam: val.typeParam == null ? "1" : val.typeParam,
      };
      getDataApi(query).then((res) => {
        console.log(val.dataSign)
        var reslutData = {};
        if (val.dataSign == "getTbkActivityInfo_1") {
          reslutData = res.result.getTbkActivityInfo_1_api.data;
        }else if(val.dataSign== "getTbkActivityInfo_2") {
          reslutData = res.result.getTbkActivityInfo_2_api.data;
        }else if(val.dataSign== "getTbkActivityInfo_3") {
          reslutData = res.result.getTbkActivityInfo_3_api.data;
        }else if(val.dataSign== "getTbkActivityInfo_4") {
          reslutData = res.result.getTbkActivityInfo_4_api.data;
        }else if(val.dataSign== "getTbkActivityInfo_5") {
          reslutData = res.result.getTbkActivityInfo_5_api.data;
        }

        if (reslutData != null) {
          this.h5_url = reslutData.link.h5_url;
          this.h5_short_link = reslutData.link.h5_short_link;
          this.ele_scheme_url = reslutData.link.ele_scheme_url;
          this.description = reslutData.description;
          this.start_time = reslutData.start_time;
          this.end_time = reslutData.end_time;
          this.title = reslutData.title;
          this.url = reslutData.link.mini_qrcode;
          this.srcList.push(reslutData.link.mini_qrcode);
          this.srcList.push(reslutData.link.picture);
          this.srcList.push(reslutData.tb_mini_qrcode);
          this.srcList.push(reslutData.tb_qr_code);
        }
      });
    },
  },
};
</script>
<style scoped>
</style>